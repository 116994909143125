
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IDevice } from '@/interfaces'
import { getLocalToken } from '@/utils'
import RandomChart from '@/components/chart/RandomChart.vue'
import Spinner from '@/components/Spinner.vue'
import BarChart from '@/components/chart/BarChart.vue'
import LineChart from '@/components/chart/LineChart.vue'
import Countdown from '@/components/Countdown.vue'
import { readIsDev } from '@/store/main/getters'
import { api } from '@/api'

export type DeviceData = {
  cpu: number,
  ram: number,
  net: {
    in: number,
    out: number,
  },
  st: {
    in: number,
    out: number,
  },
  disk: number,
}

export type SpeedTestData = {
  ts: number,
  upload: number,
  download: number,
}

export type SuData = {
  ts: number,
  cpu: {
    ts: number,
    percent: number,
    avg: number[],
  },
  ram: {
    ts: number,
    total: number,
    available: number,
    percent: number,
    used: number,
    free: number,
  },
  netio: {
    ts: number,
    bytes_sent: number,
    bytes_resv: number,
    packets_sent: number,
    packets_recv: number,
    errin: number,
    errout: number,
    dropin: number,
    dropout: number,
  },
  disk: {
    ts: number,
    total: number,
    used: number,
    free: number,
    percent: number,
  },
}

export type SuResult = {
  cpu: string,
  ram: string,
  disk: string,
  diskTotal: string,
}

export type SpeedTestResult = {
  download: string,
  upload: string,
}

export enum ChartKey {
  CPU_LOAD = 'cpuLoad',
  RAM = 'ram',
  STORAGE = 'storage',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
}

@Component({
  components: {
    BarChart,
    LineChart,
    Spinner,
    RandomChart,
    Countdown,
  },
})
export default class DeviceItem extends Vue {
  @Prop({
    required: true,
  })
  device!: IDevice

  @Prop({
    required: true,
  })
  isConnecting!: boolean

  @Prop({
    required: true,
  })
  blockNumber!: number

  @Prop({
    required: true,
  })
  feesFor24h!: number



  public ChartKey = ChartKey
  public dexRate: string = '---'

  public connection: any = null
  public pingIntervalId: any = null
  public pingInterval: number = 10000

  public speedTestData: SpeedTestData[] = []
  public suData: SuData[] = []

  public showChart: string = ''
  public showInfoBtn: boolean = false // true for dev

  public isLoadingDPNData: boolean = false

  public chartData: {
    labels: any[],
    datasets: {
      data: any[],
      label: string,
      backgroundColor: string,
      borderColor: string,
    }[],
  } = {
    labels: [],
    datasets: [],
  }

  get isDev() {
    return readIsDev(this.$store)
  }

  get suResult(): SuResult {
    let cpu = '-'
    let ram = '-'
    let disk = '-'
    let diskTotal = '-'
    const len = this.suData.length

    if (len > 0) {
      const data = this.suData[len - 1]

      cpu = data.cpu.percent.toFixed(2) + '%'
      ram = data.ram.percent.toFixed(2) + '%'
      disk = data.disk.percent.toFixed(2) + '%'
      // diskTotal = data.disk.total.toFixed(2)

      let suffix = 'MB'

      let total = data.disk.total / 1024 / 1024

      if (total > 1024) {
        total = total / 1024
        suffix = 'GB'
      }

      if (total > 1024) {
        total = total / 1024
        suffix = 'TB'
      }

      diskTotal = total.toFixed(2) + suffix
    }

    return {
      cpu,
      ram,
      disk,
      diskTotal,
    }
  }

  get speedTestResult(): SpeedTestResult {
    let download = '-'
    let upload = '-'
    const len = this.speedTestData.length

    if (len > 0) {
      const data = this.speedTestData[len - 1]
      download = (data.download / 1024 / 1024).toFixed(2)
      upload = (data.upload / 1024 / 1024).toFixed(2)
      console.debug('%c data: %s', 'color:lime;', JSON.stringify(data, null, 4))
    }

    return {
      download,
      upload,
    }
  }

  pingWS() {
    if (this.connection) {
      const payload = {
        type: 'ping',
        ts: Date.now(),
      }

      this.connection.send(JSON.stringify(payload))
    }
  }

  getNumber(val: string): string {
    return Number.parseFloat(val).toFixed(2)
  }

  toggleChart(eventType: string, from: string) {
    console.debug('%c toggleChart(event: %s, from: %s)', 'color:magenta;', eventType, from)

    if (eventType === 'click') {
      if (this.showChart === from) {
        this.showChart = ''
      } else {
        this.prepareChartData(from)
      }
    }

    if (eventType === 'mouseover') this.prepareChartData(from)

    if (eventType === 'mouseleave') this.showChart = ''
  }

  prepareChartData(from: string) {
    console.debug('%c prepareChartData(from: %s)', 'color:lime;font-size:18px;', from)

    if (from === ChartKey.CPU_LOAD || from === ChartKey.STORAGE || from === ChartKey.RAM) {
      if (this.suData.length === 0) return

      console.debug('%c suData:', 'color:lime;', this.suData)

      const labels: any[] = []
      const values: any[] = []

      let key = ''
      let title = ''
      if (from === ChartKey.CPU_LOAD) {
        key = 'cpu'
        title = 'Cpu Load (%)'
      }
      if (from === ChartKey.STORAGE) {
        key = 'disk'
        title = 'Storage (%)'
      }
      if (from === ChartKey.RAM) {
        key = 'ram'
        title = 'RAM (%)'
      }

      this.suData.forEach((item: SuData) => {
        const d = new Date(item.ts)
        const label = d.toLocaleTimeString('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        })

        const value = item[key].percent //.toFixed(2)

        labels.push(label)
        values.push(value)
      })

      this.chartData = {
        labels,
        datasets: [
          {
            data: values,
            label: title, //'CPU Load',
            backgroundColor: '#00fff0',
            borderColor: '#00fff0',
          },
        ],
      }
    }

    if (from === ChartKey.DOWNLOAD || from === ChartKey.UPLOAD) {

      if (this.speedTestData.length === 0) return

      const key = from === ChartKey.DOWNLOAD ? 'download' : 'upload'
      const label = from === ChartKey.DOWNLOAD ? ' Download (MB/s)' : ' Upload (MB/s)'
      const color = from === ChartKey.DOWNLOAD ? '#00fff0' : '#FF4A7A'

      const labels: any[] = []
      const values: any[] = []

      this.speedTestData.forEach((item: SpeedTestData) => {
        const d = new Date(item.ts)
        const label = d.toLocaleDateString('ru-RU', { day: 'numeric', month: 'numeric' })

        const value = (item[key] / 1024 / 1024).toFixed(2)

        labels.push(label)
        values.push(value)
      })

      this.chartData = {
        labels,
        datasets: [
          {
            data: values,
            label,
            backgroundColor: color,
            borderColor: color,
          },
        ],
      }
    }

    this.showChart = from
  }

  handleActivateValidator(deviceId:string) {
    this.$emit('activate-validator', deviceId)
  }

  beforeDestroy() {
    clearInterval(this.pingIntervalId)
  }

  public async activateDPN() {
    if (this.isLoadingDPNData) return

    try {
      this.isLoadingDPNData = true

      const dpnData = await api.getDPNData(this.$store.state.main.token)

      if (dpnData.data.used) {
        return this.$toast.error('', {
          message: this.$t('DPN already activated').toString(),
          duration: 5000,
          position: 'bottom',
        })
      }

      this.isLoadingDPNData = false

      window.open(dpnData.data.link, '_blank')
    } catch (err) {
      this.isLoadingDPNData = false

      console.debug('%c DeviceItem.vue activateDPN ERROR:', 'color:red;', err)
      this.$toast.error('', {
        message: this.$t(`An error occurred`).toString(),
        duration: 5000,
        position: 'bottom',
      })
    }
  }

  public async getDexRate() {
    // console.debug('%c getDexRate device: %s', 'color:magenta;', JSON.stringify(this.device, null, 4))
    try {
      const { data } = await api.getDexRate(this.device.id)
      if (data) {
        console.debug('%c DeviceItem.vue getDexRate: %s', 'color:lime;', JSON.stringify(data, null, 4))
        const rate = Number(data.dexrate)
        this.dexRate = rate ? rate.toFixed(3) : '0'
      }
    } catch (err) {
      console.debug('%c DeviceItem.vue getDexRate ERROR:', 'color:red;', err)
    }
  }

  public formatSum(number: number): string {
    // format number with spaces first 5 digits

    return number.toString().substring(0, 5).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  mounted() {
    const wsURL = 'wss://' + this.device.websocket_url
    // const wsURL = 'wss://broker1.dexnet.one/ws/v1/web-client/WNY5-QGU9-R6R8-KU6E'
    console.debug('%c DeviceItem.vue WS: %s', 'color:red;', wsURL)

    if (this.connection === null && this.device.websocket_url) {
      this.connection = new WebSocket(wsURL)

      this.connection.onopen = (event) => {
        console.debug('%c onopen', 'color:magenta;font-size:36px;', event)

        const token = 'JWT ' + getLocalToken()
        // console.debug('%c ws.onopen token: %s', 'color:gold;', token)

        this.connection.send(JSON.stringify({
          type: 'auth',
          token,
          ts: Date.now(),
        }))

        this.connection.send(JSON.stringify({
          type: 'request.su.all',
          ts: Date.now(),
        }))

        this.connection.send(JSON.stringify({
          type: 'request.st.all',
          ts: Date.now(),
        }))
      }

      this.connection.onmessage = (event) => {
        // console.debug('%c onmessage', 'color:gold;font-size:36px;', event.data)
        const msg = JSON.parse(event.data)

        if (msg.type === 'stats.st.all') {
          console.debug('%c stats.st.all len: %s', 'color:gold;', msg.data.length)

          // Скорость интернет подключения
          this.speedTestData = msg.data
        }

        if (msg.type === 'stats.su.all') {
          // Полная статистика по CPU, RAM, NET, DISK
          this.suData = msg.data

          console.debug('%c stats.su.all len: %s', 'color:orangered;', msg.data.length)
        }

        if (msg.type === 'stats.su.list') {
          // Статистика по CPU, RAM, NET, DISK
          const len = msg.data.length
          console.debug('%c stats.su.list len: %s', 'color:lime;', len)

          if (len > 0) {
            this.suData.splice(0, len)
            this.suData.push(...msg.data)
          }
        }
        // console.debug('%c onmessage', 'color:gold;font-size:36px;', JSON.stringify(msg, null, 4))
      }

      this.connection.onclose = (event) => {
        console.debug('%c onclose', 'color:orangered;font-size:36px;', event)
      }

      this.connection.onerror = (event) => {
        console.debug('%c onerror', 'color:red;font-size:36px;', event)
      }
    }

    this.pingIntervalId = setInterval(() => {
      this.pingWS()
    }, this.pingInterval)

    setTimeout(this.getDexRate, 1000)
  }
}
